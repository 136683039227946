import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
} from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import ReactGA from "react-ga4";

import AboutPage from "./views/AboutPage";
import HomePage from "./views/HomePage";
import Dashboard from "./views/Dashboard";
import ComingSoonCTA from "./components/ComingSoonCTA";
import SignupPage from "./views/SignupPage";
import HelpPage from "./views/HelpPage";
import WritingPage from "./views/WritingPage";
import ChroniclePage from "./views/ChroniclePage";
import SubscriptionInformationPage from "./views/SubscriptionInformationPage";
import PromptsPage from "./views/PromptsPage";
import TermsOfServicePage from "./views/TermsOfServicePage";
import PrivacyPolicyPage from "./views/PrivacyPolicyPage";

const domain = process.env.REACT_APP_AUTH_0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH_0_CLIENT_ID;
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const streamId = process.env.REACT_APP_GOOGLE_ANALYTICS_STREAM_ID;

function App() {
  // Google Analytics initialization
  useEffect(() => {
    if (streamId) {
      // Initialize Google Analytics
      ReactGA.initialize(streamId);

      // Enable debugging by setting testMode to true
      // ReactGA.set({ testMode: true });

      // Extract UTM parameters from current URL
      const searchParams = new URLSearchParams(window.location.search);
      const utmParams = {
        utm_source: searchParams.get("utm_source"),
        utm_medium: searchParams.get("utm_medium"),
        utm_campaign: searchParams.get("utm_campaign"),
        utm_term: searchParams.get("utm_term"),
        utm_content: searchParams.get("utm_content"),
      };

      // Send page view with UTM parameters
      ReactGA.send({
        hitType: "pageview",
        page_location: window.location.href,
        page_title: document.title,
        ...utmParams, // Keep utm_ prefix in parameter names
      });
    }
  }, []);

  if (!domain || !clientId || !apiBaseUrl) {
    console.error("Auth0 environment variables are not set.");
    return <ComingSoonCTA />;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      // 'redirect_uri' specifies where Auth0 should redirect after successful login
      authorizationParams={{
        redirect_uri: `${window.location.origin}/dashboard`,
        audience: apiBaseUrl,
        scope: "read:messages email profile",
      }}
      // handle safari and browsers that block 3rd party cookies
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <Router>
        <div>
          {/* Global navigation or header could go here */}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/help" element={<HelpPage />} />
            <Route path="/writing" element={<WritingPage />} />
            <Route path="/chronicle" element={<ChroniclePage />} />
            <Route path="/prompts" element={<PromptsPage />} />
            <Route
              path="/subscription-info"
              element={<SubscriptionInformationPage />}
            />
            <Route path="/terms-of-service" element={<TermsOfServicePage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            {/* Catch-all redirect */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          {/* Global footer could go here */}
        </div>
      </Router>
    </Auth0Provider>
  );
}

export default App;
