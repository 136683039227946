import React, { useEffect } from "react";
import ReactGA from "react-ga4";

import PublicHeader from "../components/PublicHeader";
import Footer from "../components/Footer";

export default function ChroniclePage() {
  // Google Analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/chronicle",
      title: "Chronicle Use-Case Page",
    });
  }, []);

  return (
    <div>
      <PublicHeader />

      {/* Hero */}
      <div className="mx-auto max-w-2xl px-6 py-32 sm:py-60 lg:py-48">
        <svg
          aria-hidden="true"
          className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
        >
          <defs>
            <pattern
              x="50%"
              y={-1}
              id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
              width={200}
              height={200}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
            width="100%"
            height="100%"
            strokeWidth={0}
          />
        </svg>
        <div className="text-center">
          <img
            src="illustration-capture-random.png"
            alt="Illustration Capture"
            className="mx-auto mb-4 w-5/12 h-auto"
          />
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl font-lustria font-bold sm:leading-[1.2]s">
            Chronicle your life
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Combine your thoughts to create a rich tapestry of your life. Photos
            allow you to capture what's happening, Echo allows you to capture
            how you feel.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="https://apps.apple.com/us/app/echo-ai-notes/id6503351019"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                ReactGA.event({
                  category: "Download",
                  action: "App Store Click",
                  label: "Writing Use Case Page - Hero Section",
                })
              }
            >
              <img
                src="/app-store-button-black.png"
                alt="Download on the App Store"
                className="w-32 mx-auto sm:mx-0"
              />
            </a>
            <a
              href="/"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Learn more <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
