import PublicHeader from "../components/PublicHeader";
import Footer from "../components/Footer";

export default function PrivacyPolicyPage() {
  return (
    <div>
      <PublicHeader />
      <div className="bg-white px-6 py-32 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Privacy Policy
          </h1>
          <p className="mt-6 text-xl leading-8">Last Updated: July 15, 2024</p>
          <p className="mt-6">
            This Privacy Policy describes the privacy practices of Verdai Inc.
            and how we handle personal information that we collect when you use
            our Echo product on our mobile application.
          </p>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            About Our Product
          </h2>
          <p className="mt-6">
            Through Echo, you can use your voice to quickly capture spontaneous
            thoughts. Here's how it works:
          </p>
          <p className="mt-6">
            When you record your voice, our artificial intelligence (AI) tool
            transcribes your entries accurately, providing you with an organized
            and searchable archive of your thoughts. When you ask the AI
            questions about your past reflections, it will search through your
            entries to provide meaningful and insightful answers.
          </p>
          <p className="mt-6">
            To offer this functionality, we use third-party large language
            models (LLMs), such as OpenAI's GPT model, for transcription,
            recall, and insight generation. We do not permit our third-party LLM
            providers to train on or otherwise use your data for their own
            purposes.
          </p>
          <p className="mt-6">
            In the future, we may analyze your recorded insights for key words
            to offer advertisements to you that we think you may be interested
            in. We do not share personal information with advertisers, and we do
            not sell personal information.
          </p>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            Personal Information We Collect
          </h2>
          <h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
            Information that you provide to us:
          </h3>
          <ul className="mt-6 list-disc pl-6 space-y-2">
            <li>
              <strong>Contact details</strong>, such as your first and last
              name, and email.
            </li>
            <li>
              <strong>Account information</strong>, such as the username and
              password you use to access our services.
            </li>
            <li>
              <strong>Audio recordings</strong> of your entries. These are
              transcribed into text by our AI technologies.
            </li>
            <li>
              <strong>Communications</strong> that we exchange with you,
              including when you contact us with questions, feedback or
              otherwise.
            </li>
            <li>
              <strong>Payment information</strong> needed to complete your
              transactions with us, including name, payment card information,
              and billing information. We process this information through
              third-party payment service providers, such as Apple Pay, who
              handle this information in accordance with their own privacy
              policy. We do not have access to your full payment card
              information.
            </li>
            <li>
              <strong>Marketing data</strong>, such as your preferences for
              receiving our marketing communications and details about your
              engagement with them.
            </li>
          </ul>

          <h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
            Automatic data collection.
          </h3>
          <p className="mt-6">
            We and our service providers may automatically log and combine
            information about you, your computer or mobile device, and your
            interaction over time with the services, online resources, and our
            communications, such as:
          </p>
          <ul className="mt-6 list-disc pl-6 space-y-2">
            <li>
              <strong>Device data</strong> such as your mobile device's
              operating system type and version, manufacturer and model, browser
              type, screen resolution, device type (e.g., phone, tablet), IP
              address, unique identifiers (including identifiers used for
              advertising purposes), language settings and general location
              information such as city, state, or geographic area.
            </li>
            <li>
              <strong>Online activity data</strong> such as pages or screens you
              viewed, how long you spent on a page or screen, navigation paths
              between pages or screens, information about your activity on a
              page or screen, access times, duration of access and whether you
              have opened or otherwise engage with our communications, such as
              our marketing emails or clicked links or files within them.
            </li>
          </ul>

          <h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
            Other sources.
          </h3>
          <p className="mt-6">
            We may obtain personal information from other third parties, such as
            marketing partners, publicly available sources, and data providers.
          </p>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            How We Use Personal Information
          </h2>
          <p className="mt-6">
            We use personal information for the following purposes:
          </p>
          <ul className="mt-6 list-disc pl-6 space-y-4">
            <li>
              <strong>To provide our services.</strong> We use personal
              information to operate, maintain, and provide you with our
              services. In particular, we use personal information to perform
              our contractual obligations under our terms of use.
            </li>
            <li>
              <strong>To communicate with you about our services.</strong> It is
              in our legitimate business interests to use personal information
              to respond to your requests, provide customer support, and
              communicate with you about our services, including by sending
              announcements, updates, security alerts, and support and
              administrative messages.
            </li>
            <li>
              <strong>
                To improve, monitor, personalize, and protect our services.
              </strong>{" "}
              It is in our legitimate business interests to improve and keep our
              services safe for our users, which includes:
              <ul className="mt-2 list-disc pl-6 space-y-2">
                <li>
                  understanding your needs and interests, and personalize your
                  experience with the services and our communications;
                </li>
                <li>
                  troubleshooting, testing and research and to keep the services
                  secure; and
                </li>
                <li>
                  investigating and protecting against fraudulent, harmful,
                  unauthorized, or illegal activity.
                </li>
              </ul>
            </li>
            <li>
              <strong>For research and development.</strong> We may use personal
              information for research and development purposes in our
              legitimate business interests, including to analyze and improve
              the services. As part of these activities, we may create or use
              aggregated, de-identified or other anonymized data from personal
              information we collect. We make personal information into
              anonymized data by removing information that makes the data
              personally identifiable to you. We may use this anonymized data
              and share it with third parties for our lawful business purposes,
              including to analyze and improve the services and promote our
              business.
            </li>
            <li>
              <strong>For direct marketing.</strong> We may send you direct
              marketing communications as permitted by law, including by email.
              You may opt out of our marketing communications as described in
              the "Opt-out of marketing communications" section below. <br></br>
              <br></br>
              Except where consent is required, we undertake such marketing and
              advertising on the basis of our legitimate business interests.
              Where we seek your consent, you may withdraw your consent at any
              time.
            </li>
            <li>
              <strong>For compliance and protection.</strong> We may use
              personal information to comply with legal obligations, and to
              defend us against legal claims or disputes, including to:
              <ul className="mt-2 list-disc pl-6 space-y-2">
                <li>
                  protect our, your or others' rights, privacy, safety or
                  property (including by making and defending legal claims);
                </li>
                <li>
                  audit our internal processes for compliance with legal and
                  contractual requirements and internal policies;
                </li>
                <li>
                  enforce the terms and conditions that govern the services;
                </li>
                <li>
                  prevent, identify, investigate, and deter fraudulent, harmful,
                  unauthorized, unethical or illegal activity, including
                  cyberattacks and identity theft; and
                </li>
                <li>
                  comply with applicable laws, lawful requests, and legal
                  process, such as to respond to subpoenas or requests from
                  government authorities.
                </li>
              </ul>
            </li>
          </ul>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            How We Disclose Personal Information
          </h2>
          <p className="mt-6">
            We do not sell personal information. We may disclose personal
            information to the following entities:
          </p>
          <ul className="mt-6 list-disc pl-6 space-y-2">
            <li>
              <strong>Service providers.</strong> Companies and individuals that
              provide services on our behalf or help us operate the services or
              our business (such as hosting, information technology, customer
              support, email delivery and website analytics services).
            </li>
            <li>
              <strong>Professional advisors.</strong> Professional advisors,
              such as lawyers, auditors, bankers, and insurers, where necessary
              in the course of the professional services that they render to us.
            </li>
            <li>
              <strong>Authorities and others.</strong> Law enforcement,
              government authorities and private parties, as we believe in good
              faith to be necessary or appropriate for the compliance and
              protection purposes described above.
            </li>
            <li>
              <strong>Business transferees.</strong> Acquirers and other
              relevant participants in business transactions (or negotiations
              for such transactions) involving a corporate divestiture, merger,
              consolidation, acquisition, reorganization, sale or other
              disposition of all or any portion of the business or assets of, or
              equity interests (including, in connection with a bankruptcy or
              similar proceedings).
            </li>
          </ul>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            Privacy Rights and Choices
          </h2>
          <ul className="mt-6 list-disc pl-6 space-y-4">
            <li>
              <strong>Opt out of marketing communications.</strong> You may opt
              out of marketing-related emails and other communications by
              following the opt-out or unsubscribe instructions in the
              communications you receive from us or by contacting us as provided
              in the “Contact Us” section below. You may continue to receive
              services-related and other non-marketing emails.
            </li>
            <li>
              <strong>Account choices.</strong> You can access, correct, or
              delete your account information and entries stored in Echo through
              your account settings.
            </li>
            <li>
              <strong>Personal information requests.</strong> We offer you
              choices that affect how we handle the personal information that we
              control. Depending on your location and the nature of your
              interactions with our services, you may request the following in
              relation to your personal information:
              <ul className="mt-2 list-disc pl-6 space-y-2">
                <li>
                  <strong>Information</strong> about how we have collected and
                  used personal information. We have made this information
                  available to you without having to request it by including it
                  in this Privacy Policy.
                </li>
                <li>
                  <strong>Access</strong> to a copy of the personal information
                  that we have collected about you. Where applicable, we will
                  provide the information in a portable, machine-readable,
                  readily usable format.
                </li>
                <li>
                  <strong>Correction</strong> of personal information that is
                  inaccurate or out of date.
                </li>
                <li>
                  <strong>Deletion</strong> of personal information that we no
                  longer need to provide the services or for other lawful
                  purposes.
                </li>
                <li>
                  <strong>Additional rights</strong>, such as to{" "}
                  <strong>object</strong> to and request that we{" "}
                  <strong>restrict</strong> our use of personal information.
                </li>
              </ul>
            </li>
          </ul>
          <p className="mt-6">
            To make a request, please email us or write to us as provided in the
            “Contact Us” section below. We may ask for specific information from
            you to help us confirm your identity. Depending on where you reside,
            you may be entitled to empower an authorized agent to submit
            requests on your behalf. We will require authorized agents to
            confirm their identity and authority, in accordance with applicable
            laws. You are entitled to exercise the rights described above free
            from discrimination.
          </p>
          <p className="mt-6">
            <strong>Limits on your privacy rights and choices.</strong> In some
            instances, your choices may be limited, such as where fulfilling
            your request would impair the rights of others, our ability to
            provide a service you have requested, or our ability to comply with
            our legal obligations and enforce our legal rights. If you are not
            satisfied with how we address your request, you may submit a
            complaint by contacting us as provided in the “Contact Us” section
            below. Depending on where you reside, you may have the right to
            complain to a data protection regulator where you live or work, or
            where you feel a violation has occurred.
          </p>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            Other Sites and Services
          </h2>
          <p className="mt-6">
            The services may contain links to other websites not operated or
            controlled by us. The information that you share with these
            third-party sites will be governed by the specific privacy policies
            and terms of service of the third-party sites and not by this
            Privacy Policy. By providing these links we do not imply that we
            endorse or have reviewed these sites. Please contact the third-party
            sites directly for information on their privacy practices and
            policies.
          </p>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            Security
          </h2>
          <p className="mt-6">
            We employ a number of technical, organizational, and physical
            safeguards designed to protect the personal information we collect.
            However, no security measures are failsafe, and we cannot guarantee
            the security of your personal information.
          </p>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            Children
          </h2>
          <p className="mt-6">
            Our services are not intended for use by children under 16 years of
            age. If we learn that we have collected personal information through
            the services from a child under 16 without the consent of the
            child's parent or guardian as required by law, we will delete it.
          </p>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            International Data Transfers
          </h2>
          <p className="mt-6">
            You will provide personal information directly to us in the United
            States. We may also transfer personal information to our partners
            and service providers in the United States and other jurisdictions.
            Please note that such jurisdictions may not provide the same
            protections as the data protection laws in your home country.
          </p>
          <p className="mt-6">
            When we engage in cross-border data transfers, we will ensure that
            relevant safeguards are in place to afford adequate protection for
            personal information and we will comply with applicable data
            protection laws, in particular by relying on an EU Commission or UK
            government adequacy decision or on contractual protections for the
            transfer of personal information.
          </p>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            Retention of Personal Information
          </h2>
          <p className="mt-6">
            We retain personal information we have collected about you only for
            as long as is necessary to fulfill the purposes for which it was
            collected and processed, in accordance with our retention policies,
            and in accordance with applicable laws and regulatory obligations or
            until you withdraw your consent (where applicable).
          </p>
          <p className="mt-6">
            To determine the appropriate retention period for personal
            information, we consider the amount, nature, and sensitivity of the
            personal information, the potential risk of harm from unauthorized
            use or disclosure of personal information, the purposes for which we
            use personal information and whether we can achieve those purposes
            through other means, and the applicable legal and regulatory
            requirements.
          </p>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            Changes to This Privacy Policy
          </h2>
          <p className="mt-6">
            We reserve the right to modify this Privacy Policy at any time. If
            we make material changes to this Privacy Policy, we will notify you.
          </p>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            How to Contact Us
          </h2>
          <p className="mt-6">
            <strong>Responsible entity.</strong> Verdai Inc. is the entity
            responsible for the processing of personal information under this
            Privacy Policy (as a controller, where provided under applicable
            law).
          </p>
          <p className="mt-6">
            <strong>Contact us.</strong> If you have any questions or comments
            about this Policy, our privacy practices, or if you would like to
            exercise your rights with respect to your personal information,
            please contact us by email at support@echonotes.ai.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
