import { Link } from "react-router-dom";

import PublicHeader from "../components/PublicHeader";
import Footer from "../components/Footer";

export default function TermsOfServicePage() {
  return (
    <div>
      <PublicHeader />
      <div className="bg-white px-6 py-32 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Terms of Service
          </h1>
          <p className="mt-6 text-xl leading-8">Last Updated: July 17, 2024</p>
          <div className="mt-10 max-w-2xl">
            <p>
              These Terms of Service (“Terms”) apply to your access to and use
              of the websites, mobile applications and other online products and
              services (collectively, our “Services”) provided by Verdai, Inc.
              (“Verdai”) or (“we” or “us”).
            </p>
            <p className="mt-6">
              PLEASE READ THESE TERMS CAREFULLY, INCLUDING THE MANDATORY
              ARBITRATION PROVISION IN SECTION 17, WHICH REQUIRES THAT DISPUTES
              BE RESOLVED BY FINAL AND BINDING ARBITRATION ON AN INDIVIDUAL
              BASIS, NOT A CLASS-WIDE OR CONSOLIDATED BASIS. IF YOU DO NOT WISH
              TO BE SUBJECT TO ARBITRATION, YOU MAY OPT OUT OF THE ARBITRATION
              PROVISION BY FOLLOWING THE INSTRUCTIONS PROVIDED IN SECTION 17.
            </p>
            <p className="mt-6">
              By accessing or using our Services, you agree to be bound by these
              Terms and all terms incorporated by reference. If you do not agree
              to these Terms in their entirety, do not use our Services.
            </p>
            <p className="mt-6">
              We may make changes to these Terms from time to time. If we make
              changes, we will provide you with notice of such changes, such as
              by sending an email, providing a notice through our Services or
              updating the date at the top of these Terms. Unless we say
              otherwise in our notice, the amended Terms will be effective
              immediately, and your continued use of our Services after we
              provide such notice will confirm your acceptance of the changes.
              If you do not agree to the amended Terms, you must stop using our
              Services.
            </p>
            <p className="mt-6">
              If you have any questions about these Terms or our Services,
              please contact us at legal@echonotes.ai.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              1. Privacy
            </h2>
            <p className="mt-6">
              For information about how we collect, use, share or otherwise
              process information about you, please see our Privacy Policy.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              2. Eligibility
            </h2>
            <p className="mt-6">
              You must be at least 13 years of age to use our Services. If you
              are under 18 years of age (or the age of legal majority where you
              live), you may use our Services only under the supervision of a
              parent or legal guardian who agrees to be bound by these Terms. If
              you are a parent or legal guardian of a user under the age of 18
              (or the age of legal majority), you agree to be fully responsible
              for the acts or omissions of such user in relation to our
              Services. If you use our Services on behalf of another person or
              entity, (a) all references to “you” throughout these Terms will
              include that person or entity, (b) you represent that you are
              authorized to accept these Terms on that person’s or entity’s
              behalf, and (c) in the event you or the person or entity violates
              these Terms, the person or entity agrees to be responsible to us.
            </p>
            <p className="mt-6">
              You may not use the Services if you are located in, or a citizen
              or resident of any state, country, territory or other jurisdiction
              that is embargoed by the United States or where your use of the
              Services would be illegal or otherwise violate any applicable law.
              You represent and warrant that you are not a citizen or resident
              of any such jurisdiction and that you will not use any Services
              while located in any such jurisdiction. You also may not use the
              Services if you are located in, or a citizen or resident of, any
              other jurisdiction where we have determined, at our discretion, to
              prohibit use of the Services. We may implement controls to
              restrict access to the Services from any such jurisdiction You
              will comply with this paragraph even if our methods to prevent use
              of the Services are not effective or can be bypassed.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              3. User Accounts and Account Security
            </h2>
            <p className="mt-6">
              You may need to register for an account to access some or all of
              our Services. If you register for an account, you must provide
              accurate account information and promptly update this information
              if it changes. You also must maintain the security of your account
              and promptly notify us if you discover or suspect that someone has
              accessed your account without your permission. If you permit
              others to use your account credentials, you are responsible for
              the activities of such users that occur in connection with your
              account. We reserve the right to reclaim usernames, including on
              behalf of businesses or individuals that hold legal claim,
              including trademark rights, in those usernames.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              4. Prohibited Conduct and Content
            </h2>
            <p className="mt-6">
              You will not violate any applicable law, contract, intellectual
              property right or other third-party right or commit a tort, and
              you are solely responsible for your conduct while using our
              Services. In addition, you will not:
            </p>
            <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
              <li className="flex gap-x-3">
                Engage in any harassing, threatening, intimidating, predatory or
                stalking conduct;
              </li>
              <li className="flex gap-x-3">
                Use or attempt to use another user’s account without
                authorization from that user and us;
              </li>
              <li className="flex gap-x-3">
                Use or attempt to use another user’s account without
                authorization from that user and us;
              </li>
              <li className="flex gap-x-3">
                Impersonate any person or entity or otherwise misrepresent your
                affiliation with a person or entity;
              </li>
              <li className="flex gap-x-3">
                Sell, resell or commercially use our Services;
              </li>
              <li className="flex gap-x-3">
                Copy, reproduce, distribute, publicly perform or publicly
                display all or portions of our Services, except as expressly
                permitted by us or our licensors;
              </li>
              <li className="flex gap-x-3">
                Modify our Services, remove any proprietary rights notices or
                markings, or otherwise make any derivative works based upon our
                Services;
              </li>
              <li className="flex gap-x-3">
                Use our Services other than for their intended purpose and in
                any manner that could interfere with, disrupt, negatively affect
                or inhibit other users from fully enjoying our Services or that
                could damage, disable, overburden or impair the functioning of
                our Services in any manner;
              </li>
              <li className="flex gap-x-3">
                Reverse engineer any aspect of our Services or do anything that
                might discover source code or bypass or circumvent measures
                employed to prevent or limit access to any part of our Services;
              </li>
              <li className="flex gap-x-3">
                Attempt to circumvent any content-filtering techniques we employ
                or attempt to access any feature or area of our Services that
                you are not authorized to access; Use any data mining, robots or
                similar data gathering or extraction methods designed to scrape
                or extract data from our Services;
              </li>
              <li className="flex gap-x-3">
                Develop or use any applications that interact with our Services
                without our prior written consent;
              </li>
              <li className="flex gap-x-3">
                Send, distribute or post spam, unsolicited or bulk commercial
                electronic communications, chain letters, or pyramid schemes;
              </li>
              <li className="flex gap-x-3">
                Bypass or ignore instructions contained in our robots.txt file;
              </li>
              <li className="flex gap-x-3">
                Deliberately input false, misleading, or tampered data to
                influence or manipulate the operation of the Services;
              </li>
              <li className="flex gap-x-3">
                Use, or allow third parties to use, any Output or any other
                content, data, or other information received or derived from the
                Service: to (a) directly or indirectly create, train, test, or
                otherwise improve any machine learning algorithms or artificial
                intelligence systems; (b) to develop foundation models or other
                large scale models that compete with Verdai or the Service or
                other features of the Service; or (c) in a manner that violates
                any documentation, services usage guidelines, or other
                parameters or instructions of Verdai; or
              </li>
              <li className="flex gap-x-3">
                Use our Services for any illegal or unauthorized purpose, or
                engage in, encourage or promote any activity that violates these
                Terms.
              </li>
            </ul>
            <p className="mt-6">
              Enforcement of this Section 4 is solely at our discretion, and
              failure to enforce this section in some instances does not
              constitute a waiver of our right to enforce it in other instances.
              In addition, this Section 4 does not create any private right of
              action on the part of any third party or any reasonable
              expectation that the Services will not contain any content that is
              prohibited by such rules.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              5. Materials and Data
            </h2>
            <p className="mt-6">
              You agree and instruct that we may process non-public data, input,
              and other materials provided by you ("<strong>Materials</strong>")
              and generate output based on the Materials ("
              <strong>Output</strong>"). As between you and Verdai, you retain
              ownership rights in your Materials. You represent and warrant that
              you have all rights, licenses, and permissions needed to provide
              Materials to our Services. You are responsible for your Materials
              and use of the Outputs. You are solely responsible for evaluating
              the Output for accuracy and appropriateness for your use case.
            </p>
            <p className="mt-6">
              You grant us and our subsidiaries and affiliates a perpetual,
              irrevocable, nonexclusive, royalty-free, worldwide, fully paid,
              and sublicensable license to process, reproduce, distribute, and
              otherwise use and display the Materials and perform all acts with
              respect to the Materials as may be necessary or useful for us to
              perform the Services, to maintain, provide, and develop the
              Services and otherwise develop and improve our technologies and
              offerings, and to generate or develop deidentified data from the
              Materials and any derivatives that do not directly or indirectly
              identify you or any individual (the "
              <strong>Deidentified Data</strong>"). In addition, you acknowledge
              and agree that we may monitor, collect, use, and store anonymous
              and aggregate statistics regarding your use of the Services
              (collectively, "<strong>Analytic Data</strong>").
            </p>
            <p className="mt-6">
              In order for us to provide our Services, you agree that we may
              process, transfer and store information about you in the United
              States and other countries, where you may not have the same rights
              and protections as you do under local law.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              6. Ownership; Limited License
            </h2>
            <p className="mt-6">
              All right, title, and interest in and to the Services,
              Deidentified Data, and Analytic Data including all intellectual
              property rights are owned by us or our licensors and are protected
              under both United States and foreign laws. Except as explicitly
              stated in these Terms, all rights in and to our Services are
              reserved by us or our licensors. Subject to your compliance with
              these Terms, you are hereby granted a limited, nonexclusive,
              nontransferable, non-sublicensable, revocable license to access
              and use our Services for your own personal, noncommercial use. Any
              use of our Services other than as specifically authorized in these
              Terms, without our prior written permission, is strictly
              prohibited, will terminate this license and violate our
              intellectual property rights.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              7. Trademarks
            </h2>
            <p className="mt-6">
              Our logos, our product or service names, our slogans and the look
              and feel of our Services are our trademarks and may not be copied,
              imitated or used, in whole or in part, without our prior written
              permission. All other trademarks, registered trademarks, product
              names and company names or logos mentioned on our Services are the
              property of their respective owners. Reference to any products,
              services, processes or other information by trade name, trademark,
              manufacturer, supplier or otherwise does not constitute or imply
              endorsement, sponsorship or recommendation by us.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              8. Feedback
            </h2>
            <p className="mt-6">
              You may voluntarily submit or otherwise communicate to us any
              questions, comments, suggestions, ideas, original or creative
              materials or other information about us or our Services
              (collectively, "<strong>Feedback</strong>"). You understand that
              we may use such Feedback for any purpose, commercial or otherwise,
              without acknowledgment or compensation to you, including, without
              limitation, to develop, copy, publish, or improve the Feedback in
              our sole discretion. In addition, you waive any so-called "moral
              rights" or rights of privacy or publicity in the Feedback. You
              understand that we may treat Feedback as nonconfidential.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              9. Subscription Services
            </h2>
            <p className="mt-6">
              The Services may include automatically recurring payments for
              periodic charges for certain products and/or services we may make
              available to you on a subscription basis as part of the Services
              ("<strong>Subscription Services</strong>"). If you sign up for a
              Subscription Service, (a) you may be asked to supply certain
              relevant information, such as your credit card number and its
              expiration date, your billing address and your shipping
              information, and (b) you authorize Verdai to periodically charge,
              on a going-forward recurring basis and until cancellation of
              either the recurring payments or your account, all accrued sums on
              or after the payment due date for the accrued sums. YOU REPRESENT
              AND WARRANT THAT YOU HAVE THE RIGHT TO USE ANY CREDIT CARD THAT
              YOU SUBMIT IN CONNECTION WITH A SUBSCRIPTION SERVICE. By
              submitting such information, you grant to us the right to provide
              such information to third parties for purposes of facilitating
              payment transactions for the Subscription Service. By submitting
              such information, you grant to us the right to provide such
              information to third parties for purposes of facilitating
              Subscription Services.
            </p>
            <p className="mt-6">
              The "<strong>Subscription Billing Date</strong>" is the date when
              you purchase your first subscription to the Subscription Services.
              Your account will be charged all applicable fees (the "
              <strong>Subscription Fee</strong>") for the next subscription
              period automatically on the relevant (i.e., monthly or annual)
              anniversary of the Subscription Billing Date. The subscription
              will continue unless and until you cancel your subscription, or we
              terminate it. You must cancel your subscription before it renews
              in order to avoid billing of the next periodic Subscription Fee to
              your account. We will bill the periodic Subscription Fee to the
              payment method you provide to us during registration (or to a
              different payment method if you change your payment information).
              Additional information regarding Subscription Services, including
              how to cancel your Subscription Service, can be found{" "}
              <Link
                to="/subscription-info"
                className="text-blue-600 hover:underline"
              >
                here
              </Link>{" "}
              (as applicable). Any cancellation of your subscription shall be
              effective upon the end of the then current subscription period.
            </p>

            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              10. Support
            </h2>
            <p className="mt-6">
              We are under no obligation to provide support for the Services.
              Instances where we may offer support, the support will be subject
              to published policies. Support is conducted through email at
              support@echonotes.ai.
            </p>

            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              11. Third-Party Services
            </h2>
            <p className="mt-6">
              We may make available, or we may allow third parties to make
              available access to information, products, services, and other
              materials made available by third parties on or through our
              Services (collectively, "<strong>Third-Party Services</strong>").
              Your dealings or correspondence with third parties and your use of
              or interaction with any Third-Party Services are solely between
              you and the third party. We do not control or endorse, and make no
              representations or warranties regarding, any Third-Party Services,
              and your access to and use of such Third-Party Services is at your
              own risk.
            </p>
            <p className="mt-6">
              We provide some elements of the Services through third-party
              service providers ("<strong>AI Providers</strong>"). You
              acknowledge that your Materials and Output, will be shared with
              and processed by our AI Providers, to enable your use of our
              Services. You may not use the Services in a manner that violates
              any terms or policy of any AI Provider.
            </p>

            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              12. Indemnification
            </h2>
            <p className="mt-6">
              To the fullest extent permitted by applicable law, you will
              indemnify, defend and hold harmless us and our subsidiaries and
              affiliates, and each of our respective officers, directors,
              agents, partners and employees (individually and collectively, the
              "<strong>Verdai Parties</strong>") from and against any losses,
              liabilities, claims, demands, damages, expenses or costs ("
              <strong>Claims</strong>") arising out of or related to (a) your
              access to or use of our Services; (b) your Materials or Feedback;
              (c) your violation of these Terms; (d) your violation,
              misappropriation or infringement of any rights of another
              (including intellectual property rights or privacy rights); or (e)
              your conduct in connection with our Services. You agree to
              cooperate with Verdai Parties in defending such Claims and pay all
              fees, costs and expenses associated with defending such Claims
              (including, but not limited to, attorneys' fees). You also agree
              that the Verdai Parties will have control of the defense or
              settlement, at our sole option, of any third-party Claims. This
              indemnity is in addition to, and not in lieu of, any other
              indemnities set forth in a written agreement between you and us or
              the other Verdai Parties.
            </p>

            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              13. Disclaimers
            </h2>
            <h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
              General
            </h3>
            <p className="mt-6">
              Your use of our Services is at your sole risk. Except as otherwise
              provided in a writing by us, our Services and any content therein
              are provided "as is" and "as available" without warranties of any
              kind, either express or implied, including, but not limited to,
              implied warranties of merchantability, fitness for a particular
              purpose, title, and non-infringement. In addition, we do not
              represent or warrant that our Services are accurate, complete,
              reliable, current or error-free. While we attempt to make your use
              of our Services and any content therein safe, we cannot and do not
              represent or warrant that our Services or servers are free of
              viruses or other harmful components. You assume the entire risk as
              to the quality and performance of our Services.
            </p>
            <h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
              Beta Services
            </h3>
            <p className="mt-6">
              We may provide you with the option to participate in early access
              programs to use alpha, beta, or pre-release services, products,
              features, and documentation ("<strong>Beta Services</strong>").
              Notwithstanding anything to the contrary contained in these Terms,
              Beta Services are not generally available and may contain bugs,
              errors, or defects. We may discontinue Beta Services at any time
              in our sole discretion and may never make them generally
              available.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              14. Limitation of Liability
            </h2>
            <p className="mt-6">
              <strong>
                To the fullest extent permitted by applicable law, we and the
                other Verdai Parties will not be liable to you under any theory
                of liability—whether based in contract, tort, negligence, strict
                liability, warranty, or otherwise—for any indirect,
                consequential, exemplary, incidental, punitive or special
                damages or lost profits, even if we or the other Verdai Parties
                have been advised of the possibility of such damages.
              </strong>
            </p>
            <p className="mt-6">
              <strong>
                The total liability of us and the other Verdai Parties for any
                claim arising out of or relating to these Terms or our Services,
                regardless of the form of the action, is limited to the amount
                paid by you to use our Services.
              </strong>
            </p>
            <p className="mt-6">
              <strong>
                The limitations set forth in this Section 14 will not limit or
                exclude liability for the gross negligence, fraud or intentional
                misconduct of us or the other Verdai Parties or for any other
                matters in which liability cannot be excluded or limited under
                applicable law. Additionally, some jurisdictions do not allow
                the exclusion or limitation of incidental or consequential
                damages, so the above limitations or exclusions may not apply to
                you.
              </strong>
            </p>

            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              15. Releases
            </h2>
            <h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
              Materials Release
            </h3>
            <p className="mt-6">
              Except where prohibited by applicable law, by submitting Materials
              to us, you are waiving and agreeing not to assert any copyrights
              or "moral" rights or claim resulting from our alteration of the
              Materials. You hereby release and discharge us and our agents,
              employees and assigns, and anyone acting under their
              authorization, from any claims that use of any Materials as
              authorized herein violates any of your rights, including without
              limitation any rights of publicity or privacy and intellectual
              property rights. You understand that you will not be entitled to
              any additional compensation for the permission and release granted
              herein or for the use of any of Materials as authorized herein.
            </p>
            <h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
              General Release
            </h3>
            <p className="mt-6">
              To the fullest extent permitted by applicable law, you release us
              and the other Verdai Parties from responsibility, liability,
              claims, demands and/or damages (actual and consequential) of every
              kind and nature, known and unknown (including, but not limited to,
              claims of negligence), arising out of or related to disputes
              between users and the acts or omissions of third parties. If you
              are a consumer who resides in California, you hereby waive your
              rights under California Civil Code § 1542, which provides: "A
              general release does not extend to claims that the creditor or
              releasing party does not know or suspect to exist in his or her
              favor at the time of executing the release and that, if known by
              him or her, would have materially affected his or her settlement
              with the debtor or released party."
            </p>

            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              16. Transfer and Processing Data
            </h2>
            <p className="mt-6">
              In order for us to provide our Services, you agree that we may
              process, transfer and store information about you in the United
              States and other countries, where you may not have the same rights
              and protections as you do under local law.
            </p>

            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              17. Dispute Resolution; Binding Arbitration
            </h2>
            <p className="mt-6">
              <strong>
                Please read the following section carefully because it requires
                you to arbitrate certain disputes and claims with us and limits
                the manner in which you can seek relief from us, unless you opt
                out of arbitration by following the instructions set forth
                below. No class or representative actions or arbitrations are
                allowed under this arbitration agreement. In addition,
                arbitration precludes you from suing in court or having a jury
                trial.
              </strong>
            </p>
            <p className="mt-6">
              <strong>
                No Representative Actions. You and Verdai agree that any dispute
                arising out of or related to these Terms or our Services is
                personal to you and Verdai and that any dispute will be resolved
                solely through individual action, and will not be brought as a
                class arbitration, class action or any other type of
                representative proceeding.
              </strong>
            </p>
            <p className="mt-6">
              <strong>Arbitration of Disputes.</strong> Except for small claims
              disputes in which you or Verdai seeks to bring an individual
              action in small claims court located in the county of your billing
              address or disputes in which you or Verdai seeks injunctive or
              other equitable relief for the alleged unlawful use of
              intellectual property,{" "}
              <strong>
                you and Verdai waive your rights to a jury trial and to have any
                dispute arising out of or related to these Terms or our Services
                resolved in court
              </strong>
              . Instead, for any dispute or claim that you have against us or
              relating in any way to our Services, you agree to first contact us
              and attempt to resolve the claim informally by sending a written
              notice of your claim (<strong>“Notice”</strong>) to us by email at
              legal@echonotes.ai. The Notice must (a) include your name,
              residence address, email address, and telephone number; (b)
              describe the nature and basis of the claim; and (c) set forth the
              specific relief sought. Our notice to you will be similar in form
              to that described above. If you and Verdai cannot reach an
              agreement to resolve the claim within thirty (30) days after such
              Notice is received, then either party may submit the dispute to
              binding arbitration administered by JAMS or, under the limited
              circumstances set forth above, in court. All disputes submitted to
              JAMS will be resolved through confidential, binding arbitration
              before one arbitrator. Arbitration proceedings will be held in San
              Francisco County, California or may be conducted telephonically or
              via video conference for disputes alleging damages less than $100,
              in accordance with the JAMS Streamlined Arbitration Rules and
              Procedures (<strong>“JAMS Rules”</strong>). The most recent
              version of the JAMS Rules are available on the{" "}
              <a
                href={"https://www.jamsadr.com/rules-streamlined-arbitration/"}
                className="text-blue-600 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                JAMS website
              </a>{" "}
              and are hereby incorporated by reference. You either acknowledge
              and agree that you have read and understand the JAMS Rules or
              waive your opportunity to read the JAMS Rules and waive any claim
              that the JAMS Rules are unfair or should not apply for any reason.
            </p>
            <p className="mt-6">
              You and Verdai agree that these Terms affect interstate commerce
              and that the enforceability of this Section 17 will be
              substantively and procedurally governed by the Federal Arbitration
              Act, 9 U.S.C. § 1, et seq. (the "FAA"), to the maximum extent
              permitted by applicable law. As limited by the FAA, these Terms
              and the JAMS Rules, the arbitrator will have exclusive authority
              to make all procedural and substantive decisions regarding any
              dispute and to grant any remedy that would otherwise be available
              in court, including the power to determine the question of
              arbitrability. The arbitrator may conduct only an individual
              arbitration and may not consolidate more than one individual's
              claims, preside over any type of class or representative
              proceeding or preside over any proceeding involving more than one
              individual.
            </p>
            <p className="mt-6">
              The arbitrator, Verdai, and you will maintain the confidentiality
              of any arbitration proceedings, judgments and awards, including,
              but not limited to, all information gathered, prepared and
              presented for purposes of the arbitration or related to the
              dispute(s) therein. The arbitrator will have the authority to make
              appropriate rulings to safeguard confidentiality, unless the law
              provides to the contrary. The duty of confidentiality does not
              apply to the extent that disclosure is necessary to prepare for or
              conduct the arbitration hearing on the merits, in connection with
              a court application for a preliminary remedy or in connection with
              a judicial challenge to an arbitration award or its enforcement,
              or to the extent that disclosure is otherwise required by law or
              judicial decision.
            </p>
            <p className="mt-6">
              You and Verdai agree that for any arbitration you initiate, you
              will pay the filing fee and we will pay the remaining JAMS fees
              and costs. For any arbitration initiated by Verdai, we will pay
              all JAMS fees and costs. You and Verdai agree that the state or
              federal courts of the State of California and the United States
              sitting in San Francisco County, California have exclusive
              jurisdiction over any appeals and the enforcement of an
              arbitration award.
            </p>
            <p className="mt-6">
              <strong>
                Any claim arising out of or related to these Terms or our
                Services must be filed within one year after such claim arose;
                otherwise, the claim is permanently barred, which means that you
                and Verdai will not have the right to assert the claim.
              </strong>
            </p>
            <p className="mt-6">
              <strong>
                You have the right to opt out of binding arbitration within 30
                days of the date you first accepted the terms of this Section 17
                by sending an email to legal@echonotes.ai
              </strong>
              . In order to be effective, the opt-out notice must include your
              full name and address and clearly indicate your intent to opt out
              of binding arbitration. By opting out of binding arbitration, you
              are agreeing to resolve disputes in accordance with Section 18.
            </p>
            <p className="mt-6">
              If any portion of this Section 17 is found to be unenforceable or
              unlawful for any reason, (a) the unenforceable or unlawful
              provision will be severed from these Terms; (b) severance of the
              unenforceable or unlawful provision will have no impact whatsoever
              on the remainder of this Section 17 or the parties' ability to
              compel arbitration of any remaining claims on an individual basis
              pursuant to this Section 17; and (c) to the extent that any claims
              must therefore proceed on a class, collective, consolidated, or
              representative basis, such claims must be litigated in a civil
              court of competent jurisdiction and not in arbitration, and the
              parties agree that litigation of those claims will be stayed
              pending the outcome of any individual claims in arbitration.
              Further, if any part of this Section 17 is found to prohibit an
              individual claim seeking public injunctive relief, that provision
              will have no effect to the extent such relief is allowed to be
              sought out of arbitration, and the remainder of this Section 17
              will be enforceable.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              18. Governing Law and Venue
            </h2>
            <p className="mt-6">
              Any dispute arising from these Terms and your use of our Services
              will be governed by and construed and enforced in accordance with
              the laws of the State of California, except to the extent
              preempted by U.S. federal law, without regard to conflict of law
              rules or principles (whether of the State of California or any
              other jurisdiction) that would cause the application of the laws
              of any other jurisdiction. Any dispute between the parties that is
              not subject to arbitration or cannot be heard in small claims
              court will be resolved in the state or federal courts of the State
              of California and the United States, respectively, sitting in San
              Francisco County, California.
            </p>

            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              19. Modifying and Terminating our Services
            </h2>
            <p className="mt-6">
              We reserve the right to modify our Services or to suspend or stop
              providing all or portions of our Services at any time. You also
              have the right to stop using our Services at any time. We are not
              responsible for any loss or harm related to your inability to
              access or use our Services.
            </p>

            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              20. Severability
            </h2>
            <p className="mt-6">
              If any provision or part of a provision of these Terms is
              unlawful, void or unenforceable, that provision or part of the
              provision is deemed severable from these Terms and does not affect
              the validity and enforceability of any remaining provisions.
            </p>

            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              21. Additional Terms Applicable to Mobile Devices
            </h2>
            <h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
              iOS Devices
            </h3>
            <p className="mt-6">
              The following terms apply if you install, access or use our
              Services on any device that contains the iOS mobile operating
              system (the "<strong>iOS App</strong>") developed by Apple Inc. ("
              <strong>Apple</strong>").
            </p>
            <ul className="mt-6 list-disc pl-6 space-y-4">
              <li>
                <strong>Acknowledgment.</strong> You acknowledge that these
                Terms are concluded solely between us, and not with Apple, and
                Verdai, not Apple, is solely responsible for the iOS App and the
                content thereof. In the event of any conflict between these
                Terms and the Apple Media Services Terms and Conditions as of
                the date you downloaded the iOS App, the Apple Media Services
                Terms and Conditions govern. You acknowledge and agree that you
                have had the opportunity to review the Apple Media Services
                Terms and Conditions.
              </li>
              <li>
                <strong>Scope of License.</strong> The license granted to you
                for the iOS App is limited to a non-transferable license to use
                the iOS App on any Apple-branded Products that you own or
                control and as permitted by the Usage Rules set forth in the
                Apple Media Services Terms and Conditions, except that iOS App
                may be accessed and used by other accounts associated with the
                purchaser via Family Sharing or volume purchasing.
              </li>
              <li>
                <strong>Maintenance and Support.</strong> You and Verdai
                acknowledge that Apple has no obligation whatsoever to furnish
                any maintenance and support services with respect to the iOS
                App.
              </li>
              <li>
                <strong>Warranty.</strong> You acknowledge that Apple is not
                responsible for any product warranties, whether express or
                implied by law, with respect to the iOS App. In the event of any
                failure of the iOS App to conform to any applicable warranty,
                you may notify Apple, and Apple will refund the purchase price,
                if any, paid to Apple for the iOS App by you; and to the maximum
                extent permitted by applicable law, Apple will have no other
                warranty obligation whatsoever with respect to the iOS App. The
                parties acknowledge that to the extent that there are any
                applicable warranties, any other claims, losses, liabilities,
                damages, costs or expenses attributable to any failure to
                conform to any such applicable warranty would be the sole
                responsibility of Verdai. However, you understand and agree that
                in accordance with these Terms, Verdai has disclaimed all
                warranties of any kind with respect to the iOS App, and
                therefore, there are no warranties applicable to the iOS App.
              </li>
              <li>
                <strong>Product Claims.</strong> You and Verdai acknowledge that
                as between Apple and Verdai, Verdai, not Apple, is responsible
                for addressing any claims relating to the iOS App or your
                possession and/or use of the iOS App, including, but not limited
                to (a) product liability claims, (b) any claim that the iOS App
                fails to conform to any applicable legal or regulatory
                requirement, and (c) claims arising under consumer protection,
                privacy, or similar legislation, including in connection with
                the iOS App's use of the HealthKit and HomeKit frameworks (if
                any).
              </li>
              <li>
                <strong>Intellectual Property Rights.</strong> The parties
                acknowledge that, in the event of any third-party claim that the
                iOS App or your possession and use of the iOS App infringe that
                third party's intellectual property rights, Verdai, and not
                Apple, will be solely responsible for the investigation,
                defense, settlement and discharge of any such intellectual
                property infringement claim to the extent required under these
                Terms.
              </li>
              <li>
                <strong>Legal Compliance.</strong> You represent and warrant
                that (a) you are not located in a country that is subject to a
                U.S. Government embargo, or that has been designated by the U.S.
                Government as a "terrorist supporting" country, and (b) you are
                not listed on any U.S. Government list of prohibited or
                restricted parties.
              </li>
              <li>
                <strong>Developer Name and Address.</strong> Any questions,
                complaints or claims with respect to the iOS App should be
                directed to support@echonotes.ai.
              </li>
              <li>
                <strong>Third-Party Terms of Agreement.</strong> You agree to
                comply with any applicable third-party terms when using our
                Services.
              </li>
              <li>
                <strong>Third-Party Beneficiary.</strong> The parties
                acknowledge and agree that Apple, and Apple's subsidiaries, are
                third-party beneficiaries of these Terms, and that, upon your
                acceptance of these Terms, Apple will have the right (and will
                be deemed to have accepted the right) to enforce these Terms
                against you as a third-party beneficiary thereof.
              </li>
            </ul>

            <h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
              Android Devices
            </h3>
            <p className="mt-6">
              The following terms apply if you install, access or use our
              Services on any device that contains the Android mobile operating
              system (the "<strong>Android App</strong>") developed by Google,
              Inc. ("<strong>Google</strong>"):
            </p>
            <ul className="mt-6 list-disc pl-6 space-y-4">
              <li>
                You acknowledge that these Terms are between you and us only,
                and not with Google.
              </li>
              <li>
                Your use of our Android App must comply with Google's
                then-current Android Market Terms of Service.
              </li>
              <li>
                Google is only a provider of the Android Market where You
                obtained the Android App. We, and not Google, are solely
                responsible for our Android App and our Services and Output
                available thereon. Google has no obligation or liability to You
                with respect to our Android App or these Terms.
              </li>
              <li>
                You acknowledge and agree that Google is a third-party
                beneficiary to the Terms as they relate to our Android App.
              </li>
            </ul>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              22. Miscellaneous
            </h2>
            <p className="mt-6">
              These Terms constitute the entire agreement between you and us
              relating to your access to and use of our Services.
              Notwithstanding any other provisions of these Terms, Sections 1,
              3, 5, 6 (except for the license and rights granted), 7, 8, 9 (for
              amounts due prior to the expiration or termination of these
              terms), 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, and this 22
              survive any expiration or termination of these terms. Our failure
              to exercise or enforce any right or provision of these Terms will
              not operate as a waiver of such right or provision. The section
              titles in these Terms are for convenience only and have no legal
              or contractual effect. Except as otherwise provided herein, these
              Terms are intended solely for the benefit of the parties and are
              not intended to confer third-party beneficiary rights upon any
              other person or entity. You agree that communications and
              transactions between us may be conducted electronically. These
              Terms may not be transferred, assigned or delegated by you, by
              operation of law or otherwise, without our prior written consent,
              and any attempted transfer, assignment or delegation without such
              consent will be void and without effect. We may freely transfer,
              assign or delegate these Terms or our Services, in whole or in
              part, without your prior written consent.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
