import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  Fragment,
} from "react";
import { shuffle } from "lodash";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useAuth0 } from "@auth0/auth0-react";
import { ArrowUpIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  HomeIcon,
  XMarkIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import LoadingSpinner from "../components/LoadingSpinner";
import NotLoggedInAlert from "../components/NotLoggedInAlert";
import SpinningCircle from "../components/SpinningCircle";

interface ChatMessage {
  text: string;
  role: "user" | "assistant";
}

const navigation = [{ name: "Home", href: "#", icon: HomeIcon, current: true }];

const loadingMessages = [
  "Organizing research...",
  "Diving into your data...",
  "Comparing against industry benchmarks...",
  "Aggregating market trends...",
  "Analyzing consumer behavior patterns...",
  "Calculating optimal pricing strategies...",
  "Compiling competitive analysis data...",
  "Cross-referencing product performance metrics...",
  "Evaluating regional sales data...",
  "Extracting actionable insights from user feedback...",
  "Forecasting demand curves...",
  "Gauging inventory turnover rates...",
  "Integrating the latest e-commerce algorithms...",
  "Mining for customer acquisition channels...",
  "Optimizing for conversion rate efficiency...",
  "Processing real-time analytics...",
  "Quantifying customer lifetime value...",
  "Scanning for digital marketing opportunities...",
  "Tailoring product recommendations...",
  "Uncovering social media engagement trends...",
  "Validating secure transaction protocols...",
  "Weaving through customer journey maps...",
  "Zeroing in on high-impact retention tactics...",
  "Adding Verdai's opinion...",
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Dashboard = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  const [accessToken, setAccessToken] = useState<string | null>("");
  const [userInput, setUserInput] = useState("");
  const [chatResponses, setChatResponses] = useState<ChatMessage[]>([
    {
      role: "assistant",
      text: "Hi, I'm Verdai, your helpful data analyst. How can I help you today?",
    },
  ]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);
  const [feedbackError, setFeedbackError] = useState("");
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [currentLoadingMessage, setCurrentLoadingMessage] = useState("");

  useLayoutEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatResponses]);

  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const [isLoadingChatbotResponse, setIsLoadingChatbotResponse] =
    useState(false);

  // set up timer for loading messages
  useEffect(() => {
    // Only set up the interval when the app is in a loading state
    if (isLoadingChatbotResponse) {
      const shuffledMessages = shuffle(loadingMessages);
      let messageIndex = 0;
      // Create a new interval that updates the message every 2 seconds
      const messageInterval = setInterval(() => {
        setCurrentLoadingMessage(shuffledMessages[messageIndex]);
        messageIndex++;
        // Loop back to the first message when we reach the end of the array
        if (messageIndex === shuffledMessages.length) {
          messageIndex = 0;
        }
      }, 2000); // 2000 milliseconds = 2 seconds

      // Clear the interval when the component is unmounted
      // or when the loading state is no longer true
      return () => clearInterval(messageInterval);
    }
  }, [isLoadingChatbotResponse]);

  // get and store access token
  useEffect(() => {
    const fetchAndSetAccessToken = async () => {
      const domain = process.env.REACT_APP_API_BASE_URL;
      try {
        const fetchedAccessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: domain,
            // Scope being requested must match middleware 'checkReadMessagesScope' in backend
            scope: "read:messages email profile",
          },
        });
        setAccessToken(fetchedAccessToken);
      } catch (e) {
        setIsError(true);
        setErrorMessage(
          "Failed to get authenticated access token. Try refreshing your page."
        );
      }
    };
    if (!isLoading) {
      fetchAndSetAccessToken();
    }
  }, [getAccessTokenSilently, isLoading]);

  // Debugging: Log authentication states
  useEffect(() => {
    console.log(
      `Dashboard: isLoading = ${isLoading}, isAuthenticated = ${isAuthenticated}`
    );
    if (user) {
      console.log(`Dashboard: User email = ${user.email}`);
    }
  }, [isLoading, isAuthenticated, user]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!isAuthenticated) {
    console.log("Dashboard: User is not authenticated.");
    return <NotLoggedInAlert />;
  }

  if (!user) {
    console.error("Dashboard: User is not defined. Something went wrong.");
    return <div>The user is not defined.</div>;
  }

  const handleSubmitPrompt = async (updatedUserInput: string) => {
    setIsLoadingChatbotResponse(true);

    // Use Fetch API to send the user input to your backend
    try {
      if (!accessToken) {
        throw new Error("No access token found");
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/messages`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            chatHistory: chatResponses,
            message: updatedUserInput,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const threadMessages: ChatMessage[] = await response.json();
      setChatResponses(threadMessages);
      setIsError(false);
    } catch (error) {
      console.error("Failed to send or receive the message:", error);
      setErrorMessage("Failed to communicate with the chatbot.");
      setIsError(true);
    } finally {
      setIsLoadingChatbotResponse(false);
      setUserInput("");
    }
  };

  const submitPredefinedQuestion = (question: string) => {
    setUserInput(question);

    handleSubmitPrompt(question);
  };

  const handleSubmitFeedback = async (userFeedback: string) => {
    setIsSubmittingFeedback(true);
    // Use Fetch API to send the user input to your backend
    try {
      if (!accessToken) {
        throw new Error("No access token found");
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/feedback`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            text: userFeedback,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      setFeedbackError("");
      setFeedbackText("");
      setIsFeedbackDialogOpen(false);
    } catch (error) {
      console.error("Failed to send or receive the message:", error);
      setFeedbackError("Failed to submit feedback. Email support@verdai.xyz.");
    } finally {
      setIsSubmittingFeedback(false);
    }
  };

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={classNames(
                                    item.current
                                      ? "bg-gray-50 text-indigo-600"
                                      : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current
                                        ? "text-indigo-600"
                                        : "text-gray-400 group-hover:text-indigo-600",
                                      "h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            <li className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50">
                              <button
                                onClick={handleLogout}
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700"
                              >
                                Sign out
                              </button>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-blue-1 px-6">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt="Your Company"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "text-indigo-600"
                              : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-indigo-600"
                                : "text-gray-400 group-hover:text-indigo-600",
                              "h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700"
                  >
                    Sign out
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <Transition.Root show={isFeedbackDialogOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 flex w-full max-w-xs flex-1"
            onClose={setIsFeedbackDialogOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    as="div"
                    className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-2">
                      <div className="sm:flex sm:items-start">
                        <div className="text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h2"
                            className="text-1xl font-semibold leading-6 text-gray-900"
                          >
                            Submit feedback
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-600">
                              Share feedback with the Verdai team to make the
                              product better.
                            </p>
                          </div>

                          <textarea
                            value={feedbackText}
                            className={`w-full mt-3 resize-none overflow-hidden border rounded-md mr-2 placeholder:text-gray-500 ${
                              isSubmittingFeedback
                                ? "bg-gray-200 text-gray-500 border-gray-300"
                                : "border-blue-2"
                            }`}
                            style={{
                              height: "44px",
                              minHeight: "44px",
                              maxHeight: "140px",
                              overflowY: "auto",
                            }}
                            onChange={(e) => setFeedbackText(e.target.value)}
                            onInput={(e) => {
                              const target = e.target as HTMLTextAreaElement;
                              if (target.value === "") {
                                target.style.height = "44px";
                              } else {
                                target.style.height = `${target.scrollHeight}px`;
                              }
                            }}
                            onKeyDown={(event) => {
                              if (event.code === "Enter" && !event.shiftKey) {
                                event.preventDefault();
                                handleSubmitFeedback(feedbackText);
                              }
                            }}
                            placeholder="Message Verdai..."
                            disabled={isSubmittingFeedback}
                          />
                        </div>
                      </div>

                      {feedbackError ? (
                        <div className="sm:flex sm:px-6 text-red-500">
                          {feedbackError}
                        </div>
                      ) : null}
                    </div>

                    <div className="px-4 py-5 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        className={`inline-flex w-full min-w-20 justify-center items-center rounded-md px-3 py-2 text-sm font-semibold  shadow-sm sm:ml-3 sm:w-auto ${
                          isSubmittingFeedback || feedbackText.length === 0
                            ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                            : "bg-indigo-600 text-white hover:bg-indigo-500"
                        }`}
                        onClick={() => handleSubmitFeedback(feedbackText)}
                        disabled={
                          isSubmittingFeedback || feedbackText.length === 0
                        }
                      >
                        {isSubmittingFeedback ? (
                          <SpinningCircle size="sm" />
                        ) : (
                          "Submit"
                        )}
                      </button>
                      <button
                        className="mt-3 inline-flex w-full min-w-20 justify-center items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => setIsFeedbackDialogOpen(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
            Verdai
          </div>
        </div>

        <main className="lg:pl-72 lg:h-screen main-responsive-height">
          <div className="h-full py-10 flex flex-col justify-between max-h-screen">
            <div className="px-4 sm:px-6 lg:px-8 overflow-scroll">
              <div
                id="chat-response"
                className="mx-auto max-w-2xl prose"
                style={{
                  marginTop: "20px",
                  color: isError ? "red" : "black",
                }}
              >
                {chatResponses.map((message, index) => (
                  <div
                    key={index}
                    className={`message ${
                      message.role === "user"
                        ? "userMessage rounded-lg border border-gray-200 bg-gray-50 py-2 px-3 flex items-start"
                        : "assistantMessage py-2 px-3"
                    }`}
                  >
                    <div className="pt-1">
                      {message.role === "user" && (
                        <UserCircleIcon
                          className="h-6 w-6 mr-2 text-indigo-600"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <div>
                      <Markdown remarkPlugins={[remarkGfm]}>
                        {message.text}
                      </Markdown>
                    </div>
                    <div ref={messagesEndRef} />
                  </div>
                ))}
                {isError && (
                  <div className="rounded-md bg-red-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <XCircleIcon
                          className="h-5 w-5 text-red-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">
                          Something went wrong!
                        </h3>
                        <div className="mt-2 text-sm text-red-700">
                          {errorMessage}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full px-8 bg-white mx-auto max-w-4xl">
              {isLoadingChatbotResponse && (
                <div className="text-center text-gray-600 py-4">
                  {currentLoadingMessage || "Loading..."}{" "}
                </div>
              )}
              {chatResponses.length <= 1 && (
                <div className="mx-auto flex max-w-2xl text-center py-4">
                  <button
                    className={`  ${
                      isLoadingChatbotResponse
                        ? "bg-gray-200"
                        : "bg-white hover:bg-gray-50"
                    } rounded basis-3/6 mr-2	5 text-left px-4 py-2 shadow-sm ring-1 ring-inset ring-gray-300`}
                    onClick={() =>
                      submitPredefinedQuestion(
                        "Identify 3 ways I can grow my business"
                      )
                    }
                    disabled={isLoadingChatbotResponse}
                  >
                    <span className="font-semibold text-gray-900 block text-sm">
                      Identify 3 ways I can grow my business
                    </span>
                    <span className="text-sm text-slate-600">
                      Based on my data, how should I spend my time?
                    </span>
                  </button>
                  <button
                    className={`  ${
                      isLoadingChatbotResponse
                        ? "bg-gray-200"
                        : "bg-white hover:bg-gray-50"
                    } rounded basis-3/6	ml-2 text-left px-4 py-2 shadow-sm ring-1 ring-inset ring-gray-300`}
                    onClick={() =>
                      submitPredefinedQuestion("How is my marketing going?")
                    }
                    disabled={isLoadingChatbotResponse}
                  >
                    <span className="font-semibold text-gray-900 block text-sm">
                      How is my marketing going?
                    </span>
                    <span className="text-sm text-slate-600">
                      What should I look into within marketing?
                    </span>
                  </button>
                </div>
              )}
              <div className="flex">
                <textarea
                  ref={textAreaRef}
                  value={userInput}
                  className={`flex-1 resize-none overflow-hidden border rounded-md mr-2 placeholder:text-gray-500 ${
                    isLoadingChatbotResponse
                      ? "bg-gray-200 text-gray-500 border-gray-300"
                      : "border-blue-2"
                  }`}
                  style={{
                    height: "44px",
                    minHeight: "44px",
                    maxHeight: "140px",
                    overflowY: "auto",
                  }}
                  onChange={(e) => setUserInput(e.target.value)}
                  onInput={(e) => {
                    const target = e.target as HTMLTextAreaElement;
                    if (target.value === "") {
                      target.style.height = "44px";
                    } else {
                      target.style.height = `${target.scrollHeight}px`;
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.code === "Enter" && !event.shiftKey) {
                      event.preventDefault();
                      handleSubmitPrompt(userInput);
                    }
                  }}
                  placeholder="Message Verdai..."
                  disabled={isLoadingChatbotResponse}
                />
                <button
                  onClick={() => handleSubmitPrompt(userInput)}
                  type="button"
                  style={{ height: "44px" }}
                  className={`inline-flex self-end items-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm ${
                    isLoadingChatbotResponse
                      ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                      : "bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  }`}
                  disabled={isLoadingChatbotResponse}
                >
                  {isLoadingChatbotResponse ? (
                    <SpinningCircle size="md" />
                  ) : (
                    <ArrowUpIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
              <div className="flex justify-center">
                <button
                  className="text-indigo-600 hover:text-indigo-800 flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                  onClick={() => setIsFeedbackDialogOpen(true)}
                >
                  Submit feedback
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Dashboard;
