import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";

import PublicHeader from "../components/PublicHeader";
import Footer from "../components/Footer";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";

export default function PromptsPage() {
  // Google Analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/prompts",
      title: "Prompts Page",
    });
  }, []);

  // State to manage which prompt was copied
  const [copiedIndex, setCopiedIndex] = useState<number | null>(null);

  // List of prompt ideas
  const promptIdeas = [
    {
      title: "What are the recurring to-dos or reminders I seem to overlook?",
      description:
        "Identify items that frequently slip through the cracks, bringing attention to tasks you might repeatedly postpone or overlook.",
    },
    {
      title:
        "Are there any small wins or tasks I've completed that I might have forgotten?",
      description:
        "Highlight accomplishments, even minor ones, to remind you of the progress you've made.",
    },
    {
      title: "Create a top-three list of tasks I should prioritize.",
      description: "If you log many to-dos, Echo will prioritize them for you.",
    },
    {
      title: "What recent emotions or thoughts seem to dominate my notes?",
      description:
        "If you take notes about your emotions, Echo will help analyze dominant moods or recurring feelings.",
    },
    {
      title: "Summarize any advice I seem to be giving myself lately.",
      description:
        "Summarize self-advice across notes, helping you notice your own internal guidance or wisdom.",
    },
    {
      title: "What advice do I need to hear but am not giving myself?",
      description:
        "Echo will gently suggest perspectives or insights you may be missing, revealing supportive advice you might hesitate to say to yourself.",
    },
    {
      title: "How do I describe my energy or motivation lately?",
      description:
        "Reveals fluctuations in energy or motivation, offering insights into possible patterns or triggers behind high or low days.",
    },
    {
      title: "What new concepts or ideas do my notes hint at?",
      description:
        "For raw or half-formed ideas, Echo will help you bring these to light, encouraging further exploration.",
    },
    {
      title: "What hidden strengths do my notes reveal about me?",
      description:
        "Identify subtle patterns of resilience, adaptability, or creativity you may not recognize in yourself.",
    },
    {
      title: "If my notes were a book, what would the main theme be?",
      description:
        "Discover the central theme or 'story' that runs through your notes, helping you see your recent thoughts and experiences as a cohesive narrative.",
    },
    {
      title:
        "What are my 'aha' moments, and how have they shaped my recent thinking?",
      description:
        "Find moments of insight, connecting the dots to show how these realizations are influencing your current perspective.",
    },
    {
      title: "What future advice am I unknowingly preparing for myself?",
      description:
        "Uncovers insights from your notes that, while not yet relevant, could hold value for your future self—helping you capture wisdom that you're building for tomorrow.",
    },
  ];

  // Copy text to clipboard
  const copyToClipboard = (title: string, index: number) => {
    navigator.clipboard.writeText(title).then(() => {
      setCopiedIndex(index); // Set the copied index to show "Copied!"
      setTimeout(() => setCopiedIndex(null), 2000); // Reset after 2 seconds
    });
  };

  return (
    <div>
      <PublicHeader />

      <div className="bg-white px-6 py-40 lg:py-60 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl font-lustria font-bold sm:leading-[1.5]">
            Self-Discovery with AI Prompts
          </h1>
          <p className="mt-6 text-xl leading-8 pb-4">
            Find new ways to explore your thoughts, gain fresh insights, and
            spark creativity with AI prompts to use on your Echo notes.
          </p>
          <p>
            Whether you're developing ideas, setting goals, or reflecting on the
            big questions, these prompts are designed to help you unlock deeper
            self-understanding. Use Echo to dig into your thoughts and uncover
            patterns, ideas, and new perspectives that might surprise you.
          </p>
          <div className="mt-10">
            {/* List of prompt cards */}
            <ul role="list" className="divide-y divide-gray-100">
              {promptIdeas.map((prompt, index) => (
                <li
                  key={index}
                  className="flex items-center justify-between gap-x-6 py-5"
                >
                  <div className="min-w-0">
                    <p className="text-m font-semibold leading-6 text-gray-900">
                      {prompt.title}
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      {prompt.description}
                    </p>
                  </div>
                  <button
                    onClick={() => copyToClipboard(prompt.title, index)}
                    className="flex-none rounded-md bg-white px-2.5 py-1.5 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    {copiedIndex === index ? (
                      <span>Copied!</span>
                    ) : (
                      <>
                        <DocumentDuplicateIcon
                          className="h-5 w-5 text-gray-500 inline-block mr-1"
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
