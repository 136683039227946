import PublicHeader from "../components/PublicHeader";
import Footer from "../components/Footer";

const faqs = [
  {
    id: 1,
    question: "Does Echo offer a subscription service?",
    answer:
      "Echo does not currently offer a subscription service, though we may introduce one as a premium offering in the future.",
  },
  {
    id: 2,
    question: "What are the subscription options?",
    answer:
      "If we introduce a subscription service, we will list the options here.",
  },
  {
    id: 3,
    question: "How can I cancel my active subscription?",
    answer:
      "If we introduce a subscription service, we will add the cancellation instructions here. We will likely have a method to cancel subscription from within our app.",
  },
];

export default function SubscriptionInformationPage() {
  return (
    <div>
      <PublicHeader />
      {/* Hero */}
      <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center py-24 sm:py-32 lg:px-8">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Subscription Information
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            More information will be added if we add a subscription service
            offering
          </p>
        </div>
      </div>
      {/* faq section */}
      <div className="mx-auto max-w-5xl divide-y divide-gray-900/10 px-6 py-24 sm:py-1 lg:px-8 lg:py-2">
        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
          Frequently asked questions
        </h2>
        <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
          {faqs.map((faq) => (
            <div key={faq.id} className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                {faq.question}
              </dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">
                  {faq.answer}
                </p>
              </dd>
            </div>
          ))}
        </dl>
      </div>
      <Footer />
    </div>
  );
}
