import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import LoginButton from "../components/LoginButton";

export default function Example() {
  return (
    <div className="mx-auto max-w-7xl mt-11 px-4 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-3xl">
        <div className="rounded-md bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">
                Access denied
              </h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>You must log in to see this page.</p>
              </div>
              <div className="mt-4">
                <div className="-mx-2 -my-1.5 flex">
                  <LoginButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
