import React from "react";
import { Link } from "react-router-dom";

const UseCaseHeader: React.FC = () => {
  return (
    <header className="relative w-full">
      {/* Top border */}
      <div className="absolute top-0 left-0 w-full h-2.5 flex">
        <div style={{ backgroundColor: "#7A9EBB" }} className="flex-1"></div>
        <div style={{ backgroundColor: "#66999B" }} className="flex-1"></div>
        <div style={{ backgroundColor: "#B497BD" }} className="flex-1"></div>
      </div>

      {/* Simple Nav */}
      <div className="flex flex-col items-center pt-16 lg:pt-20 lg:pb-10 lg:px-8">
        <Link to="/" className="flex flex-col items-center">
          <img
            className="w-auto h-10 md:h-12"
            src="/logo192Black.png"
            alt="Echo Logo"
          />
          <span className="text-xl md:text-3xl pt-3 md:pt-4 font-semibold text-gray-900">
            Echo
          </span>
        </Link>
        <p className="leading-7 md:leading-8 tracking-wide md:text-md lg:text-lg font-light pt-1 sm:pt-2">
          AI + voice notes for clear thinking
        </p>
      </div>
    </header>
  );
};

export default UseCaseHeader;
