import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <button
      onClick={() => loginWithRedirect()}
      className="text-sm font-semibold leading-6 text-gray-900"
    >
      Log In
    </button>
  );
};

export default LoginButton;
